import servisorLogo from "../../assets/servisorWhiteLogo.png";
import dateIcon from "../../assets/icons/dateWhite.svg";
import distanceIcon from "../../assets/icons/distanceWhite.svg";
import locationIcon from "../../assets/icons/locationWhite.svg";
import dataAnimation from "../../assets/lottie/Servisor_08_Deals.json";
import {
  Box,
  Container,
  Toolbar,
  Button,
  Typography,
  Divider,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Popover,
} from "@mui/material";
import filterIcon from "../../assets/icons/filter_list.svg";
import "./Result.scss";
import React, { useEffect, useState, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getAllActiveWorkshops } from "../../services/apis/worksshopsData.service";
import ListedShops from "./components/listedShops";
import { formatDate } from "../../services/utilities/dateFormating";
// import FilterBanner from "./components/filterBanner";
import { HeaderText } from "../../components/common/HeaderText";
import { PageLayout } from "../../components/layout/PageLayout";
import { SubHeaderText } from "../../components/common/SubHeaderText";
import dealsTag from "../../assets/tags/dealsTag.svg";
import IssueHelpButton from "../../components/common/IssueHelpButton";
import ListConditionalShops from "./components/listConditionalWorkshops";

interface ResultProps
  extends RouteComponentProps<{
    distance: string;
    lift: string;
    date: string;
    marketprice: string;
  }> {
  clicked: boolean;
  vehicles: any[];
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Vehicle {
  name: string;
}

interface Province {
  id: number;
  name: string;
}
interface Workshop {
  isWeekdayWorking: boolean;
  isSaturdayWorking: boolean;
  isSundayWorking: boolean;
  new_cars: boolean;
  old_cars: boolean;
  available_hours: number;
  vehicles: Vehicle[];
  percentage_saved: string;
  province: Province;
  latlng: [number, number];
}

const Result: React.FC<ResultProps> = () => {
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [workshopsData, setWorkshopsData] = useState();
  const [listedShops, setListedShops] = useState([]);
  const [vehicleChosen, setVehicleChosen] = useState("");
  const [workshopVehicle, setWorkshopVehicles] = useState<Workshop[]>([]);

  const conditionalVehicle = [
    "bmw",
    "bmw motorad",
    "mercedes",
    "mercedes commercial",
    "mercedes trucks",
    "mini",
    "smart",
  ];

  // Determine if the chosen vehicle is special
  const isConditionalVehcle = conditionalVehicle.includes(vehicleChosen);

  // ! Will move this and refactor
  const getActiveWorkshops = useCallback(() => {
    setIsLoading(true);

    getAllActiveWorkshops()
      .then((response) => {
        const maintenanceDetails = JSON.parse(
          localStorage.getItem("maintenance-details") ?? ""
        );
        const workshopData = response;

        const workshopsVehicles = workshopData.data.map((workshop: any) => {
          return workshop;
        });
        console.log();

        // const workshopsVehicles = workshopData.data.map((workshop: any) => {
        //     return workshop.vehicles.map((vehicle: any) => vehicle.name.toLowerCase());
        // });

        setVehicleChosen(maintenanceDetails.vehicle_make.toLowerCase());
        setWorkshopVehicles(workshopsVehicles);

        const marketPrice = maintenanceDetails.market_price;

        // const discountRate = workshopData.data.map((workshop: any) => {
        //   return workshop.rate_discount;
        // });

        // const ratePerHour = workshopData.data.map((workshop: any) => {
        //   return workshop.rate;
        // });

        // const partsDiscount = workshopData.data.map((workshop: any) => {
        //   return workshop.parts_discount;
        // });

        // const discountRate = workshopData.data[0].rate_discount;
        // const ratePerHour = workshopData.data[0].rate;
        // const partsDiscount = workshopData.data[0].parts_discount;

        // let labour = ratePerHour * maintenanceDetails.total_labour_time *parseFloat(`0.${discountRate}`);
        // const parts = maintenanceDetails.items
        //     .reduce((acc: number, item: any) => acc + item.amount_ex, 0)
        //     .toFixed(0);

        // const partsAmount = parseInt(parts);

        // const discountedWorkshopPrice =
        //     partsAmount -
        //     partsAmount * parseFloat(`0.${partsDiscount}`) +
        //     labour;

        // // discountedWorkshopPrice.toFixed(0))

        // //  const workshopDataPrice = workshopData.data.map((workshop: any) => {
        // //   return workshop;
        // // });

        // //  workshopDataPrice.discounted_workshop_price = discountedWorkshopPrice.toFixed(0);

        // workshopData.data[0].discounted_workshop_price =
        //     discountedWorkshopPrice.toFixed(0);

        // // Percentage Discount Calculation

        // const diff = marketPrice - discountedWorkshopPrice;
        // const comb = marketPrice + discountedWorkshopPrice;

        // const percentage = 100 * (diff / (comb / 2));

        // // workshopDataPrice.percentage_saved = percentage.toFixed(0);
        // workshopData.data[0].percentage_saved = percentage.toFixed(0);

        // workshopData.data.forEach((workshop: any) => {
        //   const discountRate = workshop.rate_discount;
        //   const ratePerHour = workshop.rate;
        //   const partsDiscount = workshop.parts_discount;

        //   let labour = ratePerHour * maintenanceDetails.total_labour_time;

        //   const parts = maintenanceDetails.items
        //     .reduce((acc: number, item: any) => acc + item.amount_ex, 0)
        //     .toFixed(2);

        //   const partsAmount = Number(parts);

        //   const discountedWorkshopPrice =
        //     partsAmount -
        //     partsAmount * (partsDiscount / 100) +
        //     (labour - labour * (discountRate / 100));

        //   const vat = discountedWorkshopPrice * 0.15;

        //   const totalDiscountedWorkshopPrice = discountedWorkshopPrice + vat;

        //   workshop.discounted_workshop_price =
        //     totalDiscountedWorkshopPrice.toFixed(2);
        //   const serviceFee = totalDiscountedWorkshopPrice * 0.11;
        //   // Percentage Discount Calculation
        //   const diff = marketPrice - totalDiscountedWorkshopPrice;

        //   const percentage = 100 * (diff / marketPrice);
        //   workshop.service_fee = serviceFee.toFixed(2);
        //   workshop.percentage_saved = percentage.toFixed(0);
        // });
        workshopData.data.forEach((workshop: any) => {
          const discountRate = Number(workshop.rate_discount);
          const ratePerHour = Number(workshop.rate);
          const partsDiscount = Number(workshop.parts_discount);

          const labour =
            ratePerHour * Number(maintenanceDetails.total_labour_time);

          const parts = maintenanceDetails.items.reduce(
            (acc: number, item: any) => acc + item.amount_ex,
            0
          );

          const partsAmount = Number(parts.toFixed(2));

          const discountedPartsPrice = +(
            partsAmount -
            partsAmount * (partsDiscount / 100)
          ).toFixed(2);
          const discountedLabourPrice = +(
            labour -
            labour * (discountRate / 100)
          ).toFixed(2);

          const discountedWorkshopPrice = +(
            discountedPartsPrice + discountedLabourPrice
          ).toFixed(2);

          const vat = +(discountedWorkshopPrice * 0.15).toFixed(2);
          const totalDiscountedWorkshopPrice = +(
            discountedWorkshopPrice + vat
          ).toFixed(2);

          workshop.discounted_workshop_price =
            totalDiscountedWorkshopPrice.toFixed(2);

          const serviceFee = +(totalDiscountedWorkshopPrice * 0.11).toFixed(2);
          workshop.service_fee = serviceFee.toFixed(2);

          const difference = +(
            marketPrice - totalDiscountedWorkshopPrice
          ).toFixed(2);
          const percentage = +((100 * difference) / marketPrice).toFixed(2);
          workshop.percentage_saved = percentage.toFixed(2);
        });

        localStorage.setItem(
          "selected-workshop-details",
          JSON.stringify(workshopData)
        );
        setWorkshopsData(workshopData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching active workshops: ", error);
        setIsLoading(false);
      });
  }, []);

  const [showActionSheet, setShowActionSheet] = useState(false);
  //   const [filterText, setFilterText] = useState("Savings - (High to Low)");

  const locationDetails = JSON.parse(localStorage.getItem("location") ?? "");
  const serviceDetails = JSON.parse(localStorage.getItem("servicesInfo") ?? "");
  const vehicleDetails = JSON.parse(
    localStorage.getItem("vehicle-details") ?? ""
  );
  const maintenanceDetails = JSON.parse(
    localStorage.getItem("maintenance-details") ?? ""
  );
  const areaInfoData = JSON.parse(
    localStorage.getItem("temporary-rate-data") ?? ""
  );

  const [filterWeekday, setFilterWeekday] = useState(false);
  const [filterSaturday, setFilterSaturday] = useState(false);
  const [filterSunday, setFilterSunday] = useState(false);

  const userLat = Number(locationDetails.location[0]);
  const userLng = Number(locationDetails.location[1]);

  const serviceDistance = Number(serviceDetails.distance);

  const calculateDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ): number => {
    const toRad = (value: number): number => (value * Math.PI) / 180;

    const dRadius = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const dArea =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const dCircle = 2 * Math.atan2(Math.sqrt(dArea), Math.sqrt(1 - dArea));
    const distance = dRadius * dCircle;

    return distance;
  };

  const filteredWorkshops: Workshop[] = workshopVehicle.filter(
    (workshop: Workshop) => {
      const vehicleWorkshop = workshop.vehicles.map((vehicle: any) =>
        vehicle.name.toLowerCase()
      );
      console.log("workshop vehicle: ", vehicleWorkshop);
      console.log("chosen vehicle: ", vehicleChosen);
      const matchesVehicle = vehicleWorkshop.includes(vehicleChosen);
      console.log("maches vehicle: ", matchesVehicle);

      const anyFilterActive = filterWeekday || filterSaturday || filterSunday;
      console.log("filter active: ", anyFilterActive);

      const labourHours = maintenanceDetails?.total_labour_time;
      const availableHours = workshop?.available_hours;

      const filterWorkshopHours = availableHours >= labourHours;

      const matchesFilters =
        !anyFilterActive ||
        (filterWeekday && workshop.isWeekdayWorking) ||
        (filterSaturday && workshop.isSaturdayWorking) ||
        (filterSunday && workshop.isSundayWorking);

      const carAge = vehicleDetails?.age;
      const isOld = carAge >= 10;
      const isNew = carAge < 10;

      const handlesCarAge =
        (isOld && workshop.old_cars) || (isNew && workshop.new_cars);

      //filter Provinces
      const matchesProvinces =
        workshop.province?.name === areaInfoData?.provinceName;

      const [workshopLat, workshopLng] = workshop.latlng;

      const distanceToWorkshop = calculateDistance(
        userLat,
        userLng,
        workshopLat,
        workshopLng
      );

      const allWorkshopDistance = distanceToWorkshop <= serviceDistance;

      return (
        matchesVehicle &&
        matchesFilters &&
        handlesCarAge &&
        filterWorkshopHours &&
        allWorkshopDistance &&
        matchesProvinces
      );
    }
  );

  console.log("filtered workshops: ", filteredWorkshops);

  const sortedFilterworkshops = filteredWorkshops.sort(
    (a, b) => parseFloat(b.percentage_saved) - parseFloat(a.percentage_saved)
  );

  console.log("sorted workshops: ", sortedFilterworkshops);

  //filter for condtional servicing.
  const conditionalWorkshops = sortedFilterworkshops.filter((workshop) =>
    workshop.vehicles.some((v) =>
      conditionalVehicle.includes(v.name.toLowerCase())
    )
  );

  const listedShopsWorkshops = sortedFilterworkshops.filter((workshop) => {
    const vehicles = workshop.vehicles;
    console.log("The list vehicles: ", vehicles);

    const listedChoosenListedVehcle = vehicles.some(
      (v) => v.name.toLocaleLowerCase() === vehicleChosen.toLocaleLowerCase()
    );
    console.log("list of chosen vehicle: ", listedChoosenListedVehcle);

    const conditionService = vehicles.every((v) =>
      conditionalVehicle.includes(v.name.toLocaleLowerCase())
    );
    console.log("condition service: ", listedChoosenListedVehcle);

    return listedChoosenListedVehcle && !conditionService;
  });

  const [pop, setPop] = useState(false);
  const [popover, setPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPop(true);
  };

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopover(true);
  };
  const handleClose = () => {
    setPop(false);
    setPopover(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    getActiveWorkshops();
  }, [getActiveWorkshops, filterWeekday, filterSaturday, filterSunday]);

  console.log("Listed workshops: ", sortedFilterworkshops);

  return (
    <PageLayout animationData={dataAnimation}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
            mt: 16,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <HeaderText headerText="SERVICE DETAILS" headerImage={servisorLogo} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#004FFA",
              padding: "8px 16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={dateIcon} alt="" />
              </Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {formatDate(serviceDetails.service_date)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={locationIcon} alt="" />
              </Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {locationDetails.description}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={distanceIcon} alt="" />
              </Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Within {serviceDetails.distance}km
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <SubHeaderText headerText="Select the best deal and save with Servisor!" />
          </Box>
          <Container
            sx={{
              width: { xs: "auto", lg: "70%" },
              marginTop: { xs: "0", lg: "0px" },
              borderRadius: { xs: "0", lg: "4px" },
              backgroundColor: "#FFF",
              position: "relative",
              paddingTop: { xs: "0", lg: "60px" },
              paddingBottom: { xs: "60px", lg: "70px" },
              // overflowY: 'auto',
              // height: '100vh'
            }}
          >
            <Box sx={{ display: { xs: "none", lg: "flex" } }}>
              <img src={dealsTag} alt="/" />
            </Box>
            <Box width={{ xs: "100%", lg: "529px" }}>
              <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                <SubHeaderText headerText="Select the best deal and save with Servisor!" />
              </Box>
            </Box>

            {/* <Box sx={{display: {xs: 'flex', lg: 'none'}, paddingTop: '50px'}}>
                            <img src={dealsTag} alt='/'/>
                        </Box> */}
            <Toolbar
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "#002064",
                      fontSize: "14px",
                    }}
                  >
                    Workshops
                  </Typography>
                </Box>

                <Box>
                  <Button
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                    onClick={handlePopover}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#002064",
                        fontSize: "14px",
                        textTransform: "none",
                      }}
                    >
                      Filter
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <img src={filterIcon} alt="/" />
                    </Box>
                  </Button>
                </Box>
                <Popover
                  open={popover}
                  onClose={() => {
                    setPopover(false);
                    handleClose();
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorReference="anchorEl"
                  anchorEl={anchorEl}
                >
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => setShowActionSheet(true)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* <Typography
                        sx={{
                          fontWeight: "500",
                          color: "#002064",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {filterText}
                      </Typography>
                      <Box>
                        <img src={filterIcon} alt="/" />
                      </Box> */}
                    </Button>

                    <Button
                      variant="text"
                      onClick={handleFilterClick}
                      fullWidth
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: "#002064",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        Filter
                      </Typography>
                      <Box>
                        <img src={filterIcon} alt="/" />
                      </Box>
                    </Button>
                    <Popover
                      open={pop}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorReference="anchorEl"
                      anchorEl={anchorEl}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingX: "10px",
                          paddingY: "5px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterWeekday}
                              onChange={(e) => {
                                setFilterWeekday(e.target.checked);
                                handleClose();
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              Weekdays
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterSaturday}
                              onChange={(e) => {
                                setFilterSaturday(e.target.checked);
                                handleClose();
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              Saturday
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterSunday}
                              onChange={(e) => {
                                setFilterSunday(e.target.checked);
                                handleClose();
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                textAlign: "left",

                                fontWeight: "600",
                              }}
                            >
                              Sunday
                            </Typography>
                          }
                        />
                      </Box>
                    </Popover>
                  </Box>
                </Popover>
              </Box>
            </Toolbar>
            <Box
              sx={{
                overflowY: "auto",
                height: "100vh",
                pb: "200px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&-ms-overflow-style:": {
                  display: "none",
                },
              }}
            >
              <Divider />

              <Box>
                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "75%",
                      mt: 16,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : sortedFilterworkshops.length === 0 ? (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "500",
                      pt: { xs: "20px", lg: "50px" },
                    }}
                  >
                    No workshops found for{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {vehicleChosen}
                    </span>
                    .
                  </Typography>
                ) : isConditionalVehcle ? (
                  conditionalWorkshops.length > 0 ? (
                    <ListConditionalShops
                      workshopsData={conditionalWorkshops}
                      isPricingVisible={true}
                    />
                  ) : (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "500",
                        pt: { xs: "20px", lg: "50px" },
                      }}
                    >
                      No conditional workshops found for{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {vehicleChosen}
                      </span>
                      .
                    </Typography>
                  )
                ) : listedShopsWorkshops.length > 0 ? (
                  <ListedShops
                    key={1}
                    index={1}
                    shopDetails={"54848484848"}
                    workshopsData={listedShopsWorkshops}
                    isPricingVisible={true}
                  />
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "500",
                      pt: { xs: "20px", lg: "50px" },
                    }}
                  >
                    No workshops found for{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {vehicleChosen}
                    </span>
                    .
                  </Typography>
                )}
              </Box>

              {/* <FilterBanner
                showActionSheet={showActionSheet}
                setShowActionSheet={setShowActionSheet}
                listedShops={listedShops}
                setListedShops={setListedShops}
                setFilterText={setFilterText}
              /> */}
            </Box>
            <IssueHelpButton />
          </Container>
        </>
      )}
    </PageLayout>
  );
};

export default Result;
