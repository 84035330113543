import dataAnimation from "../../assets/lottie/Servisor_09_Confirmation_1.json";
import servisorIcon from "../../assets/servisorWhiteLogo.png";
import React, { useCallback, useEffect, useState } from "react";
import "./Workshop.scss";
import Gear from "../../assets/icon-service.svg";
import Calendar from "../../assets/icons/calendar.svg";
import Calculator from "../../assets/icons/calculator.svg";
import Steering from "../../assets/icons/steering-wheel.svg";
import { Redirect, RouteComponentProps, useHistory } from "react-router";
import { uuidFetch } from "../../services/apis/workshop.service";
import { getSelectedWorkshopById } from "../../services/apis/worksshopsData.service";
import { formatDate } from "../../services/utilities/dateFormating";
import { ConditionalInfoCard } from "./components/ConditionalCardInfo";
import { ConditionalServiceCard } from "./components/CondtionalDetailsCard";
import personalTag from "../../assets/tags/bookingdetailsnew.svg";
import {
  Backdrop,
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { HeaderText } from "../../components/common/HeaderText";
import { createBooking, createPayment } from "../../services/apis/api.service";
import { PageLayout } from "../../components/layout/PageLayout";
import { SubHeaderText } from "../../components/common/SubHeaderText";

interface ResultProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const ConditionalWorkshop: React.FC<ResultProps> = () => {
  const [clicked, setClicked] = useState(false);
  const [activeBrand, setActiveBrand]: any = useState(null);
  const [uuidData, setUuidData] = useState("");

  useEffect(() => {
    setActiveBrand("../../assets/nissan.svg");
  }, []);

  const currUrl = window.location.href;

  const urlParts = currUrl.split("/");
  const urlId = urlParts[urlParts.length - 1];

  const [workshopData, setWorkshopData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const selectedWorkshopDetails = JSON.parse(
    localStorage.getItem("selected-workshop-details") ?? ""
  );

  const maintenanceDetails = JSON.parse(
    localStorage.getItem("maintenance-details") ?? ""
  );

  console.log("maintenance details: ", maintenanceDetails);

  const workShopDiscountedPercentage = JSON.parse(
    localStorage.getItem("discount-percentage") ?? ""
  )

  console.log("The discount price: ", workShopDiscountedPercentage)

  if (maintenanceDetails) {
    maintenanceDetails.market_price = 2500;

    localStorage.setItem(
      "maintenance-details",
      JSON.stringify(maintenanceDetails)
    );
  }

  // const discountPercentage =
  //     selectedWorkshopDetails?.data[0].percentage_saved;

  // const discountedWorkshopPrice =
  //     selectedWorkshopDetails?.data[0].discounted_workshop_price;

  const selectedWorkshop = selectedWorkshopDetails?.data.find(
    (workshop: any) => workshop._id === urlId
  );
  console.log("The selected workshop: ", selectedWorkshop);

  const discountPercentage = selectedWorkshop?.percentage_saved;
  console.log("The sicount percentage: ", discountPercentage);
  const marketPrice = Number(maintenanceDetails?.market_price);

  let serviceFee = 0;
  let discountedWorkshopPrice = 0;

  if (marketPrice === 2500) {
    discountedWorkshopPrice = 2500;
    serviceFee = 0.11 * 2500;
    console.log("The Service fee 1:", serviceFee);
  } else {
    discountedWorkshopPrice = Number(selectedWorkshop?.discounted_workshop_price) || marketPrice;
    serviceFee = Number(selectedWorkshop?.service_fee);
    console.log("The Service fee 2:", serviceFee);
  }

  const adminFee = Number(serviceFee);
  const workshopDeposit = discountedWorkshopPrice;
  const workshopTotalFee = workshopDeposit + adminFee;
  const totalFee = workshopTotalFee.toFixed(2);

  if ( marketPrice === 2500) {
    localStorage.setItem("service_fee", serviceFee.toString());
    localStorage.setItem(
      "discounted_workshop_price",
      discountedWorkshopPrice.toString()
    );
  }

  const findWorkShop = useCallback(() => {
    setIsLoading(true);
    getSelectedWorkshopById(urlId).then((response) => {
      console.log("set the workshop data: ", response.data);
      setWorkshopData(response.data);
      setIsLoading(false);
    });
  }, [urlId]);

  useEffect(() => {
    findWorkShop();
  }, [findWorkShop]);

  useEffect(() => {
    async function fetchUuid() {
      try {
        const data = await uuidFetch();
        setUuidData(data.uuid);

        localStorage.setItem("uuid-data", JSON.stringify(data.data));
      } catch (error) {
        console.error("Something went wrong fetching the uuid:", error);
      }
    }
    fetchUuid();
  }, []);

  localStorage.setItem("uuid-data", JSON.stringify(uuidData));

  const serviceDetails = JSON.parse(localStorage.getItem("servicesInfo") ?? "");

  const details = JSON.parse(localStorage.getItem("details-evaluation") ?? "");

  const vehicleInfomation = JSON.parse(
    localStorage.getItem("vehicle-details") ?? ""
  );

  const serviceInfomation = JSON.parse(
    localStorage.getItem("ServicePlan") ?? ""
  );

  const locationInfo = JSON.parse(localStorage.getItem("location") ?? "");

  const areaCodeDetails = localStorage.getItem("areaCode") ?? "";
  localStorage.setItem("urlId", JSON.stringify(urlId));

  const [showModal, setShowModal] = useState(false);
  const [urlData, setUrlData] = useState("");
  const history = useHistory();
  const payBooking = async () => {
    setShowModal(true);
    try {
      const data = {
        amount: totalFee.toString(),
        email: details?.email,
        name: details?.name,
        uuid: uuidData,
        mobile: details?.phonenumber,
        surname: details?.surname,
        workshop_id: urlId,
      };

      // setShowModal(true)
      const result = await createPayment(data);
      // setShowModal(false);
      setUrlData(result);
    } catch (error) {
      console.error(error);
      setShowModal(false);
    }
  };

  return (
    <PageLayout animationData={dataAnimation}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75%",
            mt: 16,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {" "}
          <HeaderText headerText="WORKSHOPS" headerImage={servisorIcon} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            disableEscapeKeyDown
            open={showModal}
          >
            <Fade in={showModal}>
              <Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "90%",
                      lg: "50%",
                    },
                    height: "80%",

                    backgroundColor: "#FFF",
                    borderRadius: "8px",
                    boxShadow: "24",
                    padding: "24px 16px",
                  }}
                >
                  {urlData ? (
                    <iframe
                      src={urlData}
                      width="100%"
                      height="100%"
                      title="pay"
                      style={{ border: "none" }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {urlData === null ? (
                        <Typography variant="body1">
                          Something went wrong. Please try again later.
                        </Typography>
                      ) : (
                        <CircularProgress />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Fade>
          </Modal>
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 64px)",

              backgroundColor: "#EAEDF6",
              paddingBottom: "100px",
              paddingTop: { xs: "80px", lg: "100px" },
            }}
          >
            <>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: { xs: "90%", lg: "544px" },
                }}
                disableGutters
              >
                <Box
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    width: "544px",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={personalTag} alt="/" />
                  </Box>

                  <Box>
                    <SubHeaderText headerText="Your booking details:" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "64px",
                    alignItems: "center",
                    borderRadius: "4px",
                    padding: "16px",
                    width: { xs: "100%", lg: "544px" },

                    background: "#FFF",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <img src={Calculator} alt="/" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#101828",
                      }}
                    >
                      Market Related Price:
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#101828",
                      }}
                    >
                      R {workshopDeposit}
                    </Typography>
                  </Box>
                </Box>
              </Container>

              <Container
                disableGutters
                sx={{
                  paddingTop: "20px",
                  width: { xs: "90%", lg: "544px" },
                  display: "flex",
                  gap: "15px",
                  flexDirection: "column",
                }}
              >
                <ConditionalInfoCard
                  workshop={workshopData}
                  discountPercentage={workShopDiscountedPercentage}
                />
                <ConditionalServiceCard
                  activeBrand={activeBrand}
                  Gear={Gear}
                  maintenanceDetails={maintenanceDetails}
                  adminFee={adminFee}
                  discountedWorkshopPrice={marketPrice}
                />

                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#101828",
                    }}
                  >
                    Additional Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "16px",
                    borderRadius: "4px",
                    backgroundColor: "#FFF",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img src={Calendar} className="gear-icon" alt="/" />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#101828",
                        }}
                      >
                        Service Date
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#101828",
                        }}
                      >
                        {formatDate(serviceDetails.service_date)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Container>

              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  zIndex: "99",
                  paddingY: "20px",
                  width: { xs: "100%", lg: "50%" },
                  borderRadius: "0",
                }}
                elevation={0}
              >
                <BottomNavigation>
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "100%", lg: "100%" },
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      sx={{
                        height: "60px",
                        paddingY: "10px",
                        width: {
                          xs: "90%",
                          lg: "544px",
                        },
                        backgroundColor: "#004FFA",
                        ":hover": {
                          backgroundColor: "#002064",
                        },
                      }}
                      variant="contained"
                      onClick={payBooking}
                    >
                      <Typography
                        textTransform="none"
                        sx={{
                          color: "#FFF",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Secure Booking
                      </Typography>
                      {clicked ? <CircularProgress></CircularProgress> : null}
                    </Button>
                  </Box>
                </BottomNavigation>
              </Paper>
            </>
          </Box>
        </>
      )}
    </PageLayout>
  );
};

export default ConditionalWorkshop;
