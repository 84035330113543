import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router";
// import { useGlobalContext } from "../../../services/context/globalContext";
// import arrowIcon from "../../../assets/icons/arrow_right.svg";

interface ListConditionShopsProps {
  workshopsData: any[];
  isPricingVisible: boolean;
}

const ListConditionalShops: React.FC<ListConditionShopsProps> = ({
  workshopsData,
  isPricingVisible,
}) => {
  const history = useHistory();
  const [storedDiscount, setStoredDiscount] = useState<string | null>(null);
  const areCode = JSON.parse(localStorage.getItem("temporary-rate-data") ?? "");
  console.log("extract the data for area rating: ", areCode);

  useEffect(() => {
    const existingDiscount = localStorage.getItem("discount-percentage");
    setStoredDiscount(existingDiscount);
  }, []);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100vh",
        pb: "100px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&-ms-overflow-style:": {
          display: "none",
        },
      }}
    >
      {workshopsData.map((elem: any, index: number) => {
        const discountToRender =
          storedDiscount !== null &&
          !isNaN(Number(storedDiscount)) &&
          Number(storedDiscount) > 0
            ? `${storedDiscount}`
            : `${elem.percentage_saved}`;

        console.log("render this: ", discountToRender);

        let areaCodeDifference = 0;
        if (areCode && areCode.rate && elem.rate) {
          const areaRate = Number(areCode.rate);
          const workshopRate = Number(elem.rate);
          if (areaRate > 0) {
            areaCodeDifference = ((areaRate - workshopRate) / areaRate) * 100;
            areaCodeDifference = Math.round(areaCodeDifference * 100) / 100;
          }
        }
        console.log("area code difference: ", areaCodeDifference);

        return (
          <Button
            key={elem._id}
            fullWidth
            onClick={(event) => {
              event.preventDefault();
              const existingDiscount = localStorage.getItem(
                "discount-percentage"
              );
              if (existingDiscount === String(elem.percentage_saved)) {
                localStorage.setItem(
                  "discount-percentage",
                  elem.percentage_saved.toString()
                );
                setStoredDiscount(elem.percentage_saved.toString());
              } else if (existingDiscount === null) {
                localStorage.setItem(
                  "discount-percentage",
                  elem.percentage_saved.toString()
                );
                setStoredDiscount(elem.percentage_saved.toString());
              }
              localStorage.setItem(
                "area-difference",
                areaCodeDifference.toString()
              );
              history.push(`/workshop-conditional-result/${elem._id}`);
            }}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(225, 235, 255, 1)",
              mb: "5px",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexShrink: 1,
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  whiteSpace: "nowrap",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      textAlign={"start"}
                      sx={{
                        color: "#002064",
                        fontWeight: "600",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {/* {shopDetails.address} */}
                      {elem.workshop_name}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ whiteSpace: "nowrap" }}>
                  <Box>
                    <Typography
                      textAlign={"start"}
                      sx={{
                        color: "rgba(0, 32, 100, 0.70)",
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        flexShrink: 1,
                        flexGrow: 1,
                        mr: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Savings on local hourly labour rate.
                    </Typography>
                  </Box>

                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      textAlign={"start"}
                      sx={{
                        color: "rgba(0, 32, 100, 0.70)",
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        flexShrink: 1,
                        flexGrow: 1,
                      }}
                    >
                      Savings on parts pricing.
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    display: isPricingVisible ? "flex" : "none",
                    flexDirection: "column",
                  }}
                >
                  {/*  */}

                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      alignSelf: "start",
                      textAlign: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#00C88C",
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        flexShrink: 0,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <strong>Save &nbsp;</strong>
                      {/* {shopDetails.rate_discount} */}
                      {`${areaCodeDifference}%`}
                    </Typography>
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: isPricingVisible ? "flex" : "none",
                    flexDirection: "column",
                  }}
                >
                  {/*  */}
                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      alignSelf: "start",
                      textAlign: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#00C88C",
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      <strong>Save &nbsp;</strong>
                      {/* {shopDetails.rate_discount} */}
                      {`${discountToRender}%`}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Button>
        );
      })}
    </Box>
  );
};

export default ListConditionalShops;
